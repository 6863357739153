import Vue from 'vue'

import App from './App.vue'
import { i18n } from './i18n'
import router from './router'

import { Trans } from './plugins/Translation'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueMeta from 'vue-meta';

// ---------------- Icons ----------------
import { faHeart, faEnvelope, faDownload, faGlobe, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faGooglePlay, faApple, faWindows, faLinux, faFacebook, faXTwitter, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons'
//import {  } from '@fortawesome/free-regular-svg-icons'

// Add here all icons that must be used in the website
library.add(faHeart, faEnvelope, faDownload, faGlobe, faChevronDown, faChevronUp, faGooglePlay, faApple, faWindows, faLinux, faFacebook, faXTwitter, faInstagram, faTiktok)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

// ---------------- Translation ----------------
Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)

// ---------------- App ----------------
Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
