<template>
  <header>
    <div id="header">
      <!-- Banner -->
      <div id="banner">
        <div id="logoTitle">
          <router-link :to="$i18nRoute({ name: 'Home', hash:'#menu' })">
          <img
            src="images/brand_logo.png"
            title="FunnyLabz"
            class="brand_icon"
          />
          </router-link>
          <h1 title="FunnyLabz" id="title">
            FunnyLabz
          </h1>
        </div>
        <p v-bind:title="$t('menu.subtitle')" id="subtitle">
          {{ $t("menu.subtitle") }}
        </p>
      </div>

      <!-- Menu selection -->
      <nav id="menu">
        <ul>
          <router-link tag="li" :to="$i18nRoute({ name: 'Apps', hash:'#menu' })" class="menu" v-bind:title="$t('menu.go_apps')">
            {{ $t("menu.apps") }}
          </router-link>
          <router-link tag="li" :to="$i18nRoute({ name: 'Goodies', hash:'#menu' })" class="menu" v-bind:title="$t('menu.go_goodies')">
            {{ $t("menu.goodies") }}
          </router-link>
          <router-link tag="li" :to="$i18nRoute({ name: 'About', hash:'#menu' })" class="menu" v-bind:title="$t('menu.go_about')">
            {{ $t("menu.about") }}
          </router-link>
          <router-link tag="li" :to="$i18nRoute({ name: 'Privacy', hash:'#menu' })" class="menu" v-bind:title="$t('menu.go_privacy')">
            {{ $t("menu.privacy") }}
          </router-link>
        </ul>
      </nav>

      <!-- Language selection -->
      <div id="selected-language" v-show="!languageMenuOpen">
        <a
          @click="toogleLanguageMenu"
          class="language-link"
        >
          <img
            v-bind:alt="languageName(currentLocale)"
            v-bind:title="languageName(currentLocale)"
            v-bind:src="'images/languages/' + currentLocale + '.png'"
            class="flag"
          />
          <font-awesome-icon v-bind:icon="['fa', 'chevron-down']" />
        </a>
      </div>
      <div id="language-menu" v-show="languageMenuOpen">
        <a
          v-for="locale in supportedLocales"
          :key="locale"
          @click="switchLocale(locale)"
          class="language-link"
          v-bind:title="languageName(locale)"
        >
          <img
            v-bind:alt="languageName(locale)"
            v-bind:src="'images/languages/' + locale + '.png'"
            class="flag"
          />
          {{ languageName(locale) }}
        </a>
        <a
          @click="toogleLanguageMenu"
          id="menu-close"
        >
          <font-awesome-icon v-bind:icon="['fa', 'chevron-up']" />
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import { Trans } from "@/plugins/Translation";
import json_languages from "@/assets/json/languages.json";

export default {
  name: "Top",
  computed: {
    supportedLocales() {
      return Trans.supportedLocales;
    },
    currentLocale() {
      return this.$i18n.locale;
    },
  },
  data() {
    return {
      languageMenuOpen: false,
    };
  },
  methods: {
    switchLocale(locale) {
      this.languageMenuOpen = false;
      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } });
        return Trans.changeLocale(locale).then(() => {
          this.$router.push(to.location);
        });
      }
    },
    toogleLanguageMenu() {
      this.languageMenuOpen = !this.languageMenuOpen;
    },

    // Language name from language code
    languageName(languageCode) {
      return json_languages[languageCode];
    },
  },
};

/*****************************
 * ---------Bubble-----------*
 *****************************/

//On va faire une fonction pour créer les bulles dans le header
function createBubbles() {
  //On cible le header
  const header = document.querySelector("#header");
  //On crée les éléments (bulles)
  const createElement = document.createElement("span");
  //On fait une variable de taille aléatoire
  var size = Math.random() * 50;
  //On modifie le css des span
  createElement.style.width = 20 + size + "px";
  createElement.style.height = 20 + size + "px";
  let randomleft = Math.random() * innerWidth;
  if (randomleft < window.innerWidth) {
    createElement.style.right = randomleft + "px";
  }
  //On l'ajoute au header
  header.appendChild(createElement);

  //On donne un temps de vie à nos bulles (4 secondes)
  setTimeout(() => {
    createElement.remove();
  }, 4000);
}
//On fait un interval de temps où les bulles vont apparaître
setInterval(createBubbles, 50);
</script>

<style lang="scss" src="@/assets/styles/HeaderComponent/main.scss" />
