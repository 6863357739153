import Vue from 'vue'
import VueRouter from 'vue-router'
import { Trans } from '@/plugins/Translation'

function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
}

Vue.use(VueRouter)

const routes = [{
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    beforeEnter: Trans.routeMiddleware,
    children: [
      // <!> IF WE UPDATE THE PATHS, WE NEED TO ALSO UPDATE vue.config.js file to generate a correct sitemap !
      {
        path: '/',
        name: 'Home',
        component: load('Home')
      },
      {
        path: 'apps',
        name: 'Apps',
        component: load('Apps')
      },
      {
        path: 'goodies',
        name: 'Goodies',
        component: load('Goodies')
      },
      {
        path: 'app/:selectedAppId',
        name: 'App',
        component: load('App'),
      },
      {
        path: 'about',
        name: 'About',
        component: load('About')
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: load('Privacy')
      }
    ]
  },
  {
    path: '*',
    redirect() {
      return Trans.defaultLocale;
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      // console.log("Saved position");
      return savedPosition;
    }
    if (to.hash) {
      // console.log("Moving to hash '"+to.hash+"' of the page");
      return { selector: to.hash, behavior: "smooth" };
    } else {
      // console.log("Moving to top of the page");
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

export default router
