
<template>
  <div id="page">
    <Header/>
    <router-view/>
    <Bottom/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Bottom from '@/components/Bottom.vue'

export default {
  name: 'App',
  components: {
    Header,
    Bottom
  }
}

</script>

<style lang="scss" src="@/assets/styles/global.scss" />
